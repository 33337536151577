@import "https://rsms.me/inter/inter.css";

/* styles/color.css */

:root {
  --puck-color-rose-01: #4a001c;
  --puck-color-rose-02: #670833;
  --puck-color-rose-03: #87114c;
  --puck-color-rose-04: #a81a66;
  --puck-color-rose-05: #bc5089;
  --puck-color-rose-06: #cc7ca5;
  --puck-color-rose-07: #d89aba;
  --puck-color-rose-08: #e3b8cf;
  --puck-color-rose-09: #efd6e3;
  --puck-color-rose-10: #f6eaf1;
  --puck-color-rose-11: #faf4f8;
  --puck-color-rose-12: #fef8fc;
  --puck-color-azure-01: #00175d;
  --puck-color-azure-02: #002c77;
  --puck-color-azure-03: #014292;
  --puck-color-azure-04: #0158ad;
  --puck-color-azure-05: #3479be;
  --puck-color-azure-06: #6499cf;
  --puck-color-azure-07: #88b0da;
  --puck-color-azure-08: #abc7e5;
  --puck-color-azure-09: #cfdff0;
  --puck-color-azure-10: #e7eef7;
  --puck-color-azure-11: #f3f6fb;
  --puck-color-azure-12: #f7faff;
  --puck-color-green-01: #002000;
  --puck-color-green-02: #043604;
  --puck-color-green-03: #084e08;
  --puck-color-green-04: #0c680c;
  --puck-color-green-05: #1d882f;
  --puck-color-green-06: #2faa53;
  --puck-color-green-07: #56c16f;
  --puck-color-green-08: #7dd78b;
  --puck-color-green-09: #b8e8bf;
  --puck-color-green-10: #ddf3e0;
  --puck-color-green-11: #eff8f0;
  --puck-color-green-12: #f3fcf4;
  --puck-color-yellow-01: #211000;
  --puck-color-yellow-02: #362700;
  --puck-color-yellow-03: #4c4000;
  --puck-color-yellow-04: #645a00;
  --puck-color-yellow-05: #877614;
  --puck-color-yellow-06: #ab9429;
  --puck-color-yellow-07: #bfac4e;
  --puck-color-yellow-08: #d4c474;
  --puck-color-yellow-09: #e6deb1;
  --puck-color-yellow-10: #f3efd9;
  --puck-color-yellow-11: #f9f7ed;
  --puck-color-yellow-12: #fcfaf0;
  --puck-color-red-01: #4c0000;
  --puck-color-red-02: #6a0a10;
  --puck-color-red-03: #8a1422;
  --puck-color-red-04: #ac1f35;
  --puck-color-red-05: #bf5366;
  --puck-color-red-06: #ce7e8e;
  --puck-color-red-07: #d99ca8;
  --puck-color-red-08: #e4b9c2;
  --puck-color-red-09: #efd7db;
  --puck-color-red-10: #f6eaec;
  --puck-color-red-11: #faf4f5;
  --puck-color-red-12: #fff9fa;
  --puck-color-grey-01: #181818;
  --puck-color-grey-02: #292929;
  --puck-color-grey-03: #404040;
  --puck-color-grey-04: #5a5a5a;
  --puck-color-grey-05: #767676;
  --puck-color-grey-06: #949494;
  --puck-color-grey-07: #ababab;
  --puck-color-grey-08: #c3c3c3;
  --puck-color-grey-09: #dcdcdc;
  --puck-color-grey-10: #efefef;
  --puck-color-grey-11: #f5f5f5;
  --puck-color-grey-12: #fafafa;
  --puck-color-black: #000000;
  --puck-color-white: #ffffff;
}

/* styles/typography.css */

:root {
  --puck-font-size-scale-base-unitless: 12;
  --puck-font-size-xxxs-unitless: 12;
  --puck-font-size-xxs-unitless: 14;
  --puck-font-size-xs-unitless: 16;
  --puck-font-size-s-unitless: 18;
  --puck-font-size-m-unitless: 21;
  --puck-font-size-l-unitless: 24;
  --puck-font-size-xl-unitless: 28;
  --puck-font-size-xxl-unitless: 36;
  --puck-font-size-xxxl-unitless: 48;
  --puck-font-size-xxxxl-unitless: 56;
  --puck-font-size-xxxs: calc(1rem * var(--puck-font-size-xxxs-unitless) / 16);
  --puck-font-size-xxs: calc(1rem * var(--puck-font-size-xxs-unitless) / 16);
  --puck-font-size-xs: calc(1rem * var(--puck-font-size-xs-unitless) / 16);
  --puck-font-size-s: calc(1rem * var(--puck-font-size-s-unitless) / 16);
  --puck-font-size-m: calc(1rem * var(--puck-font-size-m-unitless) / 16);
  --puck-font-size-l: calc(1rem * var(--puck-font-size-l-unitless) / 16);
  --puck-font-size-xl: calc(1rem * var(--puck-font-size-xl-unitless) / 16);
  --puck-font-size-xxl: calc(1rem * var(--puck-font-size-xxl-unitless) / 16);
  --puck-font-size-xxxl: calc(1rem * var(--puck-font-size-xxxl-unitless) / 16);
  --puck-font-size-xxxxl: calc( 1rem * var(--puck-font-size-xxxxl-unitless) / 16 );
  --puck-font-size-base: var(--puck-font-size-xs);
  --line-height-reset: 1;
  --line-height-xs: calc( var(--space-m-unitless) / var(--puck-font-size-m-unitless) );
  --line-height-s: calc( var(--space-m-unitless) / var(--puck-font-size-s-unitless) );
  --line-height-m: calc( var(--space-m-unitless) / var(--puck-font-size-xs-unitless) );
  --line-height-l: calc( var(--space-m-unitless) / var(--puck-font-size-xxs-unitless) );
  --line-height-xl: calc( var(--space-m-unitless) / var(--puck-font-size-scale-base-unitless) );
  --line-height-base: var(--line-height-m);
  --fallback-font-stack:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Helvetica Neue,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
  --puck-font-family: Inter, var(--fallback-font-stack);
  --puck-font-family-monospaced:
    ui-monospace,
    "Cascadia Code",
    "Source Code Pro",
    Menlo,
    Consolas,
    "DejaVu Sans Mono",
    monospace;
}

@supports (font-variation-settings: normal) {
  :root {
    --puck-font-family: InterVariable, var(--fallback-font-stack);
  }
}

/* styles.css */

/* css-module:/home/runner/work/puck/puck/packages/core/components/ActionBar/styles.module.css/#css-module-data */

._ActionBar_151w5_1 {
  display: flex;
  width: auto;
  padding: 4px;
  padding-right: 4px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-radius: 8px;
  background: var(--puck-color-grey-01);
  color: var(--puck-color-white);
  font-family: var(--puck-font-family);
  gap: 4px;
  min-height: 26px;
}

._ActionBar-actionsLabel_151w5_16 {
  color: var(--puck-color-grey-08);
  display: flex;
  font-size: var(--puck-font-size-xxxs);
  font-weight: 500;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._ActionBar-group_151w5_29 {
  border-inline-start: 0.5px solid var(--puck-color-grey-05);
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
}

._ActionBar-group_151w5_29:last-of-type {
  padding-right: 0;
}

._ActionBar-group_151w5_29:empty {
  display: none;
}

._ActionBar-action_151w5_16 {
  background: transparent;
  border: none;
  color: var(--puck-color-grey-08);
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 50ms ease-in;
}

._ActionBar-action_151w5_16:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: -2px;
}

@media (hover: hover) and (pointer: fine) {
  ._ActionBar-action_151w5_16:hover {
    color: var(--puck-color-azure-06);
    transition: none;
  }
}

._ActionBar-action_151w5_16:active {
  color: var(--puck-color-azure-07);
  transition: none;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/AutoField/styles.module.css/#css-module-data */

._InputWrapper_1l5m8_1 {
  color: var(--puck-color-grey-04);
  padding: 16px;
  padding-bottom: 12px;
  display: block;
}

._InputWrapper_1l5m8_1 + ._InputWrapper_1l5m8_1 {
  border-top: 1px solid var(--puck-color-grey-09);
  margin-top: 8px;
}

._Input_1l5m8_1 ._InputWrapper_1l5m8_1 {
  padding: 0px;
}

._Input_1l5m8_1 * {
  box-sizing: border-box;
}

._Input_1l5m8_1 ._InputWrapper_1l5m8_1 + ._InputWrapper_1l5m8_1 {
  border-top: 0px;
  margin-top: 12px;
}

._Input-label_1l5m8_26 {
  align-items: center;
  color: var(--puck-color-grey-04);
  display: flex;
  padding-bottom: 12px;
  font-size: var(--puck-font-size-xxs);
  font-weight: 600;
}

._Input-labelIcon_1l5m8_35 {
  color: var(--puck-color-grey-07);
  display: flex;
  margin-right: 4px;
  padding-left: 4px;
}

._Input-disabledIcon_1l5m8_42 {
  color: var(--puck-color-grey-05);
  margin-left: auto;
}

._Input-input_1l5m8_47 {
  background: var(--puck-color-white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--puck-color-grey-09);
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  padding: 12px 15px;
  transition: border-color 50ms ease-in;
  width: 100%;
}

select._Input-input_1l5m8_47 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23c3c3c3'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 12px) calc(50% + 3px);
  background-repeat: no-repeat;
  background-color: var(--puck-color-white);
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  ._Input_1l5m8_1:has(> input):hover ._Input-input_1l5m8_47:not([readonly]),
  ._Input_1l5m8_1:has(> textarea):hover ._Input-input_1l5m8_47:not([readonly]) {
    border-color: var(--puck-color-grey-05);
    transition: none;
  }
  ._Input_1l5m8_1:has(> select):hover ._Input-input_1l5m8_47:not([disabled]) {
    background-color: var(--puck-color-azure-12);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%235a5a5a'><polygon points='0,0 100,0 50,50'/></svg>");
    border-color: var(--puck-color-grey-05);
    transition: none;
  }
}

._Input-input_1l5m8_47:focus {
  border-color: var(--puck-color-grey-05);
  outline: 2px solid var(--puck-color-azure-05);
  transition: none;
}

._Input--readOnly_1l5m8_91 > ._Input-input_1l5m8_47,
._Input--readOnly_1l5m8_91 > select._Input-input_1l5m8_47 {
  background-color: var(--puck-color-grey-11);
  border-color: var(--puck-color-grey-09);
  color: var(--puck-color-grey-04);
  cursor: default;
  opacity: 1;
  outline: 0;
  transition: none;
}

._Input-radioGroupItems_1l5m8_102 {
  display: flex;
  border: 1px solid var(--puck-color-grey-09);
  border-radius: 4px;
  flex-wrap: wrap;
}

._Input-radio_1l5m8_102 {
  border-right: 1px solid var(--puck-color-grey-09);
  flex-grow: 1;
}

._Input-radio_1l5m8_102:first-of-type {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

._Input-radio_1l5m8_102:first-of-type ._Input-radioInner_1l5m8_119 {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

._Input-radio_1l5m8_102:last-of-type {
  border-bottom-right-radius: 4px;
  border-right: 0;
  border-top-right-radius: 4px;
}

._Input-radio_1l5m8_102:last-of-type ._Input-radioInner_1l5m8_119 {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

._Input-radioInner_1l5m8_119 {
  background-color: var(--puck-color-white);
  color: var(--puck-color-grey-04);
  cursor: pointer;
  font-size: var(--puck-font-size-xxxs);
  padding: 8px 12px;
  text-align: center;
  transition: background-color 50ms ease-in;
}

._Input-radio_1l5m8_102:has(:focus-visible) {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
  position: relative;
}

@media (hover: hover) and (pointer: fine) {
  ._Input-radioInner_1l5m8_119:hover {
    background-color: var(--puck-color-azure-12);
    transition: none;
  }
}

._Input--readOnly_1l5m8_91 ._Input-radioInner_1l5m8_119 {
  background-color: var(--puck-color-white);
  color: var(--puck-color-grey-04);
  cursor: default;
}

._Input-radio_1l5m8_102 ._Input-radioInput_1l5m8_164:checked ~ ._Input-radioInner_1l5m8_119 {
  background-color: var(--puck-color-azure-11);
  color: var(--puck-color-azure-04);
  font-weight: 500;
}

._Input--readOnly_1l5m8_91 ._Input-radioInput_1l5m8_164:checked ~ ._Input-radioInner_1l5m8_119 {
  background-color: var(--puck-color-grey-11);
  color: var(--puck-color-grey-04);
}

._Input-radio_1l5m8_102 ._Input-radioInput_1l5m8_164 {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

textarea._Input-input_1l5m8_47 {
  margin-bottom: -4px;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/AutoField/fields/ArrayField/styles.module.css/#css-module-data */

._ArrayField_1go19_5 {
  display: flex;
  flex-direction: column;
  background-color: var(--puck-color-grey-09);
  border: 1px solid var(--puck-color-grey-09);
  border-radius: 4px;
}

._ArrayField--isDraggingFrom_1go19_13 {
  background-color: var(--puck-color-azure-11);
  overflow: hidden;
}

._ArrayField-addButton_1go19_18 {
  background-color: var(--puck-color-white);
  border: none;
  border-radius: 3px;
  display: flex;
  color: var(--puck-color-azure-05);
  justify-content: center;
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 14px;
  text-align: left;
  transition: background-color 50ms ease-in;
}

._ArrayField--hasItems_1go19_33 > ._ArrayField-addButton_1go19_18 {
  border-top: 1px solid var(--puck-color-grey-09);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._ArrayField-addButton_1go19_18:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
  position: relative;
}

@media (hover: hover) and (pointer: fine) {
  ._ArrayField_1go19_5:not(._ArrayField--isDraggingFrom_1go19_13) > ._ArrayField-addButton_1go19_18:hover {
    background: var(--puck-color-azure-12);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}

._ArrayField_1go19_5:not(._ArrayField--isDraggingFrom_1go19_13) > ._ArrayField-addButton_1go19_18:active {
  background: var(--puck-color-azure-11);
  color: var(--puck-color-azure-04);
  transition: none;
}

._ArrayFieldItem_1go19_63 {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  position: relative;
}

._ArrayFieldItem_1go19_63:not(._ArrayFieldItem--isDragging_1go19_71):not(:first-of-type)::before {
  background-color: var(--puck-color-grey-09);
  position: absolute;
  width: 100%;
  height: 1px;
  content: "";
  z-index: 1;
  top: -1px;
}

._ArrayFieldItem--isExpanded_1go19_81::before {
  display: none;
}

._ArrayFieldItem--isExpanded_1go19_81 {
  border-bottom: 0;
  outline-offset: 0px !important;
  outline: 1px solid var(--puck-color-azure-07) !important;
  z-index: 2;
}

._ArrayFieldItem--isDragging_1go19_71 {
  box-shadow: color-mix(in srgb, var(--puck-color-grey-06) 25%, transparent) 0 3px 6px 0;
}

._ArrayFieldItem--isDragging_1go19_71 ._ArrayFieldItem-summary_1go19_97:active {
  background-color: var(--puck-color-white);
}

._ArrayFieldItem_1go19_63 + ._ArrayFieldItem_1go19_63 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._ArrayFieldItem-summary_1go19_97 {
  background: var(--puck-color-white);
  color: var(--puck-color-grey-04);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: space-between;
  font-size: var(--puck-font-size-xxs);
  list-style: none;
  padding: 12px 15px;
  padding-bottom: 13px;
  position: relative;
  overflow: hidden;
  transition: background-color 50ms ease-in;
}

._ArrayFieldItem_1go19_63:first-of-type > ._ArrayFieldItem-summary_1go19_97 {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

._ArrayField--addDisabled_1go19_128 > ._ArrayFieldItem_1go19_63:last-of-type:not(._ArrayFieldItem--isExpanded_1go19_81) > ._ArrayFieldItem-summary_1go19_97 {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

._ArrayField--addDisabled_1go19_128 > ._ArrayFieldItem--isExpanded_1go19_81:last-of-type {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

._ArrayFieldItem-summary_1go19_97:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}

@media (hover: hover) and (pointer: fine) {
  ._ArrayFieldItem-summary_1go19_97:hover {
    background-color: var(--puck-color-azure-12);
    transition: none;
  }
}

._ArrayFieldItem-summary_1go19_97:active {
  background-color: var(--puck-color-azure-11);
  transition: none;
}

._ArrayFieldItem--isExpanded_1go19_81 > ._ArrayFieldItem-summary_1go19_97 {
  background: var(--puck-color-azure-11);
  color: var(--puck-color-azure-04);
  font-weight: 600;
  transition: none;
}

._ArrayFieldItem-body_1go19_164 {
  background: var(--puck-color-white);
  display: none;
}

._ArrayFieldItem--isExpanded_1go19_81 > ._ArrayFieldItem-body_1go19_164 {
  display: block;
}

._ArrayFieldItem-fieldset_1go19_173 {
  border: none;
  border-top: 1px solid var(--puck-color-grey-09);
  margin: 0;
  min-width: 0;
  padding: 16px 15px;
}

._ArrayFieldItem-rhs_1go19_181 {
  display: flex;
  gap: 4px;
  align-items: center;
}

._ArrayFieldItem-actions_1go19_187 {
  color: var(--puck-color-grey-04);
  display: flex;
  gap: 4px;
  opacity: 0;
}

._ArrayFieldItem-summary_1go19_97:focus-within > ._ArrayFieldItem-rhs_1go19_181 > ._ArrayFieldItem-actions_1go19_187,
._ArrayFieldItem-summary_1go19_97:hover > ._ArrayFieldItem-rhs_1go19_181 > ._ArrayFieldItem-actions_1go19_187 {
  opacity: 1;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/IconButton/IconButton.module.css/#css-module-data */

._IconButton_swpni_1 {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 4px;
  color: currentColor;
  display: flex;
  font-family: var(--puck-font-family);
  justify-content: center;
  padding: 4px;
  transition: background-color 50ms ease-in, color 50ms ease-in;
}

._IconButton_swpni_1:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: -2px;
}

@media (hover: hover) and (pointer: fine) {
  ._IconButton_swpni_1:hover:not(._IconButton--disabled_swpni_20) {
    background: var(--puck-color-azure-12);
    color: var(--puck-color-azure-04);
    cursor: pointer;
    transition: none;
  }
}

._IconButton_swpni_1:active {
  background: var(--puck-color-azure-11);
  transition: none;
}

._IconButton-title_swpni_33 {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

._IconButton--disabled_swpni_20 {
  color: var(--puck-color-grey-07);
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Loader/styles.module.css/#css-module-data */

@keyframes _loader-animation_nacdm_1 {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

._Loader_nacdm_13 {
  background: transparent;
  border-radius: 100%;
  border: 2px solid currentColor;
  border-bottom-color: transparent;
  display: inline-block;
  animation: _loader-animation_nacdm_1 1s 0s infinite linear;
  animation-fill-mode: both;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/DragIcon/styles.module.css/#css-module-data */

._DragIcon_17p8x_1 {
  color: var(--puck-color-grey-05);
  cursor: grab;
  padding: 4px;
  border-radius: 4px;
}

._DragIcon--disabled_17p8x_8 {
  cursor: no-drop;
}

@media (hover: hover) and (pointer: fine) {
  ._DragIcon_17p8x_1:not(._DragIcon--disabled_17p8x_8):hover {
    color: var(--puck-color-azure-05);
    background-color: var(--puck-color-azure-12);
  }
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/ExternalInput/styles.module.css/#css-module-data */

._ExternalInput-actions_8wgzm_1 {
  display: flex;
}

._ExternalInput-button_8wgzm_5 {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: var(--puck-color-white);
  border: 1px solid var(--puck-color-grey-09);
  border-radius: 4px;
  color: var(--puck-color-azure-04);
  padding: 12px 16px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: background-color 50ms ease-in;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

._ExternalInput--dataSelected_8wgzm_24 ._ExternalInput-button_8wgzm_5 {
  color: var(--puck-color-grey-03);
  display: block;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

._ExternalInput--readOnly_8wgzm_31 ._ExternalInput-button_8wgzm_5 {
  background-color: var(--puck-color-grey-11);
}

._ExternalInput-detachButton_8wgzm_35 {
  border: 1px solid var(--puck-color-grey-09);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--puck-color-grey-12);
  color: var(--puck-color-grey-05);
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  position: relative;
  transition: background-color 50ms ease-in, color 50ms ease-in;
  margin-left: -1px;
}

._ExternalInput-button_8wgzm_5:focus-visible,
._ExternalInput-detachButton_8wgzm_35:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
  z-index: 1;
}

@media (hover: hover) and (pointer: fine) {
  ._ExternalInput_8wgzm_1:not(._ExternalInput--readOnly_8wgzm_31) ._ExternalInput-button_8wgzm_5:hover,
  ._ExternalInput_8wgzm_1:not(._ExternalInput--readOnly_8wgzm_31) ._ExternalInput-detachButton_8wgzm_35:hover {
    background: var(--puck-color-azure-12);
    transition: none;
  }
  ._ExternalInput_8wgzm_1:not(._ExternalInput--readOnly_8wgzm_31) ._ExternalInput-detachButton_8wgzm_35:hover {
    color: var(--puck-color-azure-04);
  }
}

._ExternalInput_8wgzm_1:not(._ExternalInput--readOnly_8wgzm_31) ._ExternalInput-button_8wgzm_5:active,
._ExternalInput_8wgzm_1:not(._ExternalInput--readOnly_8wgzm_31) ._ExternalInput-detachButton_8wgzm_35:active {
  background: var(--puck-color-azure-11);
  transition: none;
}

._ExternalInputModal_8wgzm_79 {
  color: var(--puck-color-black);
  display: grid;
  grid-template-rows: min-content minmax(128px, 100%) min-content;
  grid-template-columns: 100%;
  position: relative;
  min-height: 50dvh;
  max-height: 90dvh;
}

._ExternalInputModal-grid_8wgzm_89 {
  display: flex;
  flex-direction: column;
}

@media (min-width: 458px) {
  ._ExternalInputModal-grid_8wgzm_89 {
    display: grid;
    grid-template-columns: 100%;
  }
  ._ExternalInputModal--filtersToggled_8wgzm_100 ._ExternalInputModal-grid_8wgzm_89 {
    grid-template-columns: 25% 75%;
  }
}

._ExternalInputModal-filters_8wgzm_105 {
  border-bottom: 1px solid var(--puck-color-grey-09);
}

._ExternalInputModal--filtersToggled_8wgzm_100 ._ExternalInputModal-filters_8wgzm_105 {
  display: none;
}

@media (min-width: 458px) {
  ._ExternalInputModal-filters_8wgzm_105 {
    border-right: 1px solid var(--puck-color-grey-09);
    display: none;
  }
  ._ExternalInputModal--filtersToggled_8wgzm_100 ._ExternalInputModal-filters_8wgzm_105 {
    display: block;
  }
}

._ExternalInputModal-masthead_8wgzm_124 {
  background-color: var(--puck-color-grey-12);
  border-bottom: 1px solid var(--puck-color-grey-09);
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;
}

._ExternalInputModal-tableWrapper_8wgzm_133 {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  flex-grow: 1;
}

._ExternalInputModal-table_8wgzm_133 {
  border-collapse: unset;
  border-spacing: 0px;
  color: var(--puck-color-grey-02);
  position: relative;
  z-index: 0;
  min-width: 100%;
}

._ExternalInputModal-thead_8wgzm_149 {
  background-color: var(--puck-color-white);
  position: sticky;
  top: 0;
  z-index: 1;
}

._ExternalInputModal-th_8wgzm_149 {
  border-bottom: 1px solid var(--puck-color-grey-09);
  color: var(--puck-color-grey-04);
  font-weight: 500;
  font-size: 14px;
  padding: 16px 24px;
}

._ExternalInputModal-td_8wgzm_164 {
  border-bottom: 1px solid var(--puck-color-grey-10);
  padding: 16px 24px;
}

._ExternalInputModal-tr_8wgzm_169 ._ExternalInputModal-td_8wgzm_164:first-of-type {
  font-weight: 500;
  width: 1%;
  white-space: nowrap;
}

@media (hover: hover) and (pointer: fine) {
  ._ExternalInputModal-tbody_8wgzm_176 ._ExternalInputModal-tr_8wgzm_169:hover {
    background: var(--puck-color-azure-12);
    color: var(--puck-color-azure-04);
    cursor: pointer;
    position: relative;
    margin-left: -5px;
  }
  ._ExternalInputModal-tbody_8wgzm_176 ._ExternalInputModal-tr_8wgzm_169:hover ._ExternalInputModal-td_8wgzm_164:first-of-type {
    border-left: 4px solid var(--puck-color-azure-04);
    padding-left: 20px;
  }
}

._ExternalInputModal-tbody_8wgzm_176 ._ExternalInputModal-tr_8wgzm_169:last-of-type ._ExternalInputModal-td_8wgzm_164 {
  border-bottom: none;
}

._ExternalInputModal-tableWrapper_8wgzm_133 {
  display: none;
}

._ExternalInputModal--hasData_8wgzm_202 ._ExternalInputModal-tableWrapper_8wgzm_133 {
  display: block;
}

._ExternalInputModal-loadingBanner_8wgzm_206 {
  display: none;
  background-color: color-mix(in srgb, var(--puck-color-white) 90%, transparent);
  padding: 64px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

._ExternalInputModal--isLoading_8wgzm_223 ._ExternalInputModal-loadingBanner_8wgzm_206 {
  display: flex;
}

._ExternalInputModal-searchForm_8wgzm_227 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex-grow: 1;
}

@media (min-width: 458px) {
  ._ExternalInputModal-searchForm_8wgzm_227 {
    flex-wrap: nowrap;
  }
}

._ExternalInputModal-search_8wgzm_227 {
  display: flex;
  background: var(--puck-color-white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--puck-color-grey-09);
  border-radius: 4px;
  flex-grow: 1;
  transition: border-color 50ms ease-in;
}

._ExternalInputModal-search_8wgzm_227:focus-within {
  border-color: var(--puck-color-grey-05);
  outline: 2px solid var(--puck-color-azure-05);
  transition: none;
}

@media (hover: hover) and (pointer: fine) {
  ._ExternalInputModal-search_8wgzm_227:hover {
    border-color: var(--puck-color-grey-05);
    transition: none;
  }
}

._ExternalInputModal-searchIcon_8wgzm_264 {
  align-items: center;
  background: var(--puck-color-grey-12);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 1px solid var(--puck-color-grey-09);
  color: var(--puck-color-grey-07);
  display: flex;
  justify-content: center;
  padding: 12px 15px;
  transition: color 50ms ease-in;
}

._ExternalInputModal-search_8wgzm_227:focus-within ._ExternalInputModal-searchIcon_8wgzm_264 {
  color: var(--puck-color-grey-04);
  transition: none;
}

@media (hover: hover) and (pointer: fine) {
  ._ExternalInputModal-search_8wgzm_227:hover ._ExternalInputModal-searchIcon_8wgzm_264 {
    color: var(--puck-color-grey-04);
    transition: none;
  }
}

._ExternalInputModal-searchIconText_8wgzm_289 {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

._ExternalInputModal-searchInput_8wgzm_299 {
  border: none;
  border-radius: 4px;
  background: var(--puck-color-white);
  font-family: inherit;
  font-size: 14px;
  padding: 12px 15px;
  width: 100%;
}

._ExternalInputModal-searchInput_8wgzm_299:focus {
  outline: 0;
}

._ExternalInputModal-searchActions_8wgzm_313 {
  display: flex;
  gap: 8px;
  height: 44px;
  width: 100%;
}

@media (min-width: 458px) {
  ._ExternalInputModal-searchActions_8wgzm_313 {
    width: auto;
  }
}

._ExternalInputModal-searchActionIcon_8wgzm_326 {
  align-self: center;
}

._ExternalInputModal-footerContainer_8wgzm_330 {
  background-color: var(--puck-color-grey-12);
  border-top: 1px solid var(--puck-color-grey-09);
  color: var(--puck-color-grey-04);
  padding: 16px;
}

._ExternalInputModal-footer_8wgzm_330 {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Modal/styles.module.css/#css-module-data */

._Modal_ikbaj_1 {
  background: color-mix(in srgb, var(--puck-color-black) 75%, transparent);
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  padding: 32px;
}

._Modal--isOpen_ikbaj_15 {
  display: flex;
}

._Modal-inner_ikbaj_19 {
  width: 100%;
  max-width: 1024px;
  border-radius: 8px;
  overflow: hidden;
  background: var(--puck-color-white);
  display: flex;
  flex-direction: column;
  max-height: 90dvh;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Heading/styles.module.css/#css-module-data */

._Heading_qxrry_1 {
  display: block;
  color: var(--puck-color-black);
  font-weight: 700;
  margin: 0;
}

._Heading_qxrry_1 b {
  font-weight: 700;
}

._Heading--xxxxl_qxrry_12 {
  font-size: var(--puck-font-size-xxxxl);
  letter-spacing: 0.08ch;
  font-weight: 800;
}

._Heading--xxxl_qxrry_18 {
  font-size: var(--puck-font-size-xxxl);
}

._Heading--xxl_qxrry_22 {
  font-size: var(--puck-font-size-xxl);
}

._Heading--xl_qxrry_26 {
  font-size: var(--puck-font-size-xl);
}

._Heading--l_qxrry_30 {
  font-size: var(--puck-font-size-l);
}

._Heading--m_qxrry_34 {
  font-size: var(--puck-font-size-m);
}

._Heading--s_qxrry_38 {
  font-size: var(--puck-font-size-s);
}

._Heading--xs_qxrry_42 {
  font-size: var(--puck-font-size-xs);
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Button/Button.module.css/#css-module-data */

._Button_1t64k_1 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: var(--puck-color-white);
  display: inline-flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0.05ch;
  font-family: var(--puck-font-family);
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  transition: background-color 50ms ease-in;
  cursor: pointer;
  white-space: nowrap;
  margin: 0;
}

._Button_1t64k_1:hover,
._Button_1t64k_1:active {
  transition: none;
}

._Button--medium_1t64k_29 {
  min-height: 34px;
  padding-bottom: 7px;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 7px;
}

._Button--large_1t64k_37 {
  padding-bottom: 11px;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 11px;
}

._Button-icon_1t64k_44 {
  margin-top: 2px;
}

._Button--primary_1t64k_48 {
  background: var(--puck-color-azure-04);
}

._Button_1t64k_1:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}

@media (hover: hover) and (pointer: fine) {
  ._Button--primary_1t64k_48:hover {
    background-color: var(--puck-color-azure-03);
  }
}

._Button--primary_1t64k_48:active {
  background-color: var(--puck-color-azure-02);
}

._Button--secondary_1t64k_67 {
  border: 1px solid currentColor;
  color: currentColor;
}

@media (hover: hover) and (pointer: fine) {
  ._Button--secondary_1t64k_67:hover {
    background-color: var(--puck-color-azure-12);
    color: var(--puck-color-black);
  }
}

._Button--secondary_1t64k_67:active {
  background-color: var(--puck-color-azure-11);
  color: var(--puck-color-black);
}

._Button--flush_1t64k_84 {
  border-radius: 0;
}

._Button--disabled_1t64k_88,
._Button--disabled_1t64k_88:hover {
  background-color: var(--puck-color-grey-07);
  color: var(--puck-color-grey-03);
  cursor: not-allowed;
}

._Button--fullWidth_1t64k_95 {
  justify-content: center;
  width: 100%;
}

._Button-spinner_1t64k_100 {
  padding-left: 8px;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/AutoField/fields/ObjectField/styles.module.css/#css-module-data */

._ObjectField_1ua3y_5 {
  display: flex;
  flex-direction: column;
  background-color: var(--puck-color-white);
  border: 1px solid var(--puck-color-grey-09);
  border-radius: 4px;
}

._ObjectField-fieldset_1ua3y_13 {
  border: none;
  margin: 0;
  min-width: 0;
  padding: 16px 15px;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Drawer/styles.module.css/#css-module-data */

._Drawer_1oa7v_1 {
  font-family: var(--puck-font-family);
}

._DrawerItem--disabled_1oa7v_5 ._DrawerItem-draggable_1oa7v_5 {
  background: var(--puck-color-grey-11);
  color: var(--puck-color-grey-05);
  cursor: not-allowed;
}

._DrawerItem-default_1oa7v_11 ._DrawerItem-draggableWrapper_1oa7v_11 {
  padding-bottom: 12px;
}

._DrawerItem_1oa7v_5:last-of-type ._DrawerItem-default_1oa7v_11 ._DrawerItem-draggableWrapper_1oa7v_11 {
  padding-bottom: 0;
}

._DrawerItem-draggable_1oa7v_5 {
  background: var(--puck-color-white);
  padding: 12px;
  display: flex;
  border: 1px var(--puck-color-grey-09) solid;
  border-radius: 4px;
  font-size: var(--puck-font-size-xxs);
  justify-content: space-between;
  align-items: center;
  transition: background-color 50ms ease-in, color 50ms ease-in;
}

._DrawerItem_1oa7v_5:focus-visible {
  outline: 0;
}

._Drawer_1oa7v_1:not(._Drawer--isDraggingFrom_1oa7v_36) ._DrawerItem_1oa7v_5:focus-visible ._DrawerItem-draggable_1oa7v_5 {
  border-radius: 4px;
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}

@media (hover: hover) and (pointer: fine) {
  ._Drawer_1oa7v_1:not(._Drawer--isDraggingFrom_1oa7v_36) ._DrawerItem_1oa7v_5:not(._DrawerItem--disabled_1oa7v_5) ._DrawerItem-draggable_1oa7v_5:hover {
    background-color: var(--puck-color-azure-12);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}

._DrawerItem-name_1oa7v_54 {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/DraggableComponent/styles.module.css/#css-module-data */

._DraggableComponent_1bhad_1 {
  pointer-events: auto;
  --overlay-background: color-mix( in srgb, var(--puck-color-azure-08) 30%, transparent );
}

._DraggableComponent--isDragging_1bhad_11 {
  background: color-mix(in srgb, var(--puck-color-azure-08) 10%, transparent);
  overflow: hidden;
}

._DraggableComponent-contents_1bhad_16 {
  position: relative;
  pointer-events: none;
  z-index: 0;
}

._DraggableComponent-contents_1bhad_16::before,
._DraggableComponent-contents_1bhad_16::after {
  content: " ";
  display: table;
}

._DraggableComponent-overlay_1bhad_29 {
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  outline-offset: -2px;
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
  z-index: 1;
}

._DraggableComponent_1bhad_1:focus-visible > ._DraggableComponent-overlay_1bhad_29 {
  outline: 1px solid var(--puck-color-azure-05);
}

._DraggableComponent--isDragging_1bhad_11 > ._DraggableComponent-overlay_1bhad_29 {
  outline: 2px var(--puck-color-azure-09) solid !important;
}

._DraggableComponent-loadingOverlay_1bhad_49 {
  background: var(--puck-color-white);
  color: var(--puck-color-grey-03);
  border-radius: 4px;
  display: flex;
  padding: 8px;
  top: 8px;
  right: 8px;
  position: absolute;
  pointer-events: all;
  box-sizing: border-box;
  opacity: 0.8;
  z-index: 1;
}

._DraggableComponent_1bhad_1:hover:not(._DraggableComponent--isLocked_1bhad_65) > ._DraggableComponent-overlay_1bhad_29 {
  background: var(--overlay-background);
  pointer-events: none;
}

._DraggableComponent--forceHover_1bhad_71 > ._DraggableComponent-overlay_1bhad_29 {
  background: var(--overlay-background);
  pointer-events: none;
}

._DraggableComponent_1bhad_1:not(._DraggableComponent--isSelected_1bhad_76):hover > ._DraggableComponent-overlay_1bhad_29 {
  outline: 2px var(--puck-color-azure-09) solid !important;
}

._DraggableComponent--indicativeHover_1bhad_81 > ._DraggableComponent-overlay_1bhad_29 {
  pointer-events: none;
}

._DraggableComponent_1bhad_1:not(._DraggableComponent--isSelected_1bhad_76):has(._DraggableComponent_1bhad_1:hover > ._DraggableComponent-overlay_1bhad_29) > ._DraggableComponent-overlay_1bhad_29 {
  display: none;
}

._DraggableComponent--isSelected_1bhad_76 > ._DraggableComponent-overlay_1bhad_29 {
  outline: 2px var(--puck-color-azure-07) solid !important;
}

._DraggableComponent--isSelected_1bhad_76 > ._DraggableComponent-actionsOverlay_1bhad_97 {
  display: block;
  position: sticky;
  z-index: 2;
}

._DraggableComponent-actions_1bhad_97 {
  position: absolute;
  width: auto;
  cursor: grab;
  display: none;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: right top;
}

._DraggableComponent--isSelected_1bhad_76 > ._DraggableComponent-actionsOverlay_1bhad_97 > ._DraggableComponent-actions_1bhad_97 {
  display: flex;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/DropZone/styles.module.css/#css-module-data */

._DropZone_djoti_1 {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 100%;
  outline-offset: -2px;
  width: 100%;
}

._DropZone-content_djoti_10 {
  min-height: 128px;
  height: 100%;
}

._DropZone--userIsDragging_djoti_15 ._DropZone-content_djoti_10 {
  pointer-events: all;
}

._DropZone--userIsDragging_djoti_15:not(._DropZone--draggingOverArea_djoti_19):not(._DropZone--draggingNewComponent_djoti_20) > ._DropZone-content_djoti_10 {
  pointer-events: none;
}

._DropZone--isAreaSelected_djoti_26,
._DropZone--draggingOverArea_djoti_19:not(:has(._DropZone--hoveringOverArea_djoti_27)),
._DropZone--hoveringOverArea_djoti_27:not(._DropZone--isDisabled_djoti_28):not(._DropZone--isRootZone_djoti_29):not(._DropZone--hasChildren_djoti_30) {
  background: color-mix(in srgb, var(--puck-color-azure-09) 30%, transparent);
  outline: 2px dashed var(--puck-color-azure-08);
}

._DropZone_djoti_1:not(._DropZone--hasChildren_djoti_30) {
  background: color-mix(in srgb, var(--puck-color-azure-09) 30%, transparent);
  outline: 2px dashed var(--puck-color-azure-08);
}

._DropZone--isDestination_djoti_40 {
  outline: 2px dashed var(--puck-color-azure-04) !important;
}

._DropZone--isDestination_djoti_40:not(._DropZone--isRootZone_djoti_29) {
  background: color-mix(in srgb, var(--puck-color-azure-09) 30%, transparent) !important;
}

._DropZone-item_djoti_52 {
  position: relative;
}

._DropZone-hitbox_djoti_56 {
  position: absolute;
  bottom: -12px;
  height: 24px;
  width: 100%;
  z-index: 1;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/SidebarSection/styles.module.css/#css-module-data */

._SidebarSection_125qe_1 {
  display: flex;
  position: relative;
  flex-direction: column;
  color: var(--puck-color-black);
}

._SidebarSection_125qe_1:last-of-type {
  flex-grow: 1;
}

._SidebarSection-title_125qe_12 {
  background: var(--puck-color-white);
  padding: 16px;
  border-bottom: 1px solid var(--puck-color-grey-09);
  border-top: 1px solid var(--puck-color-grey-09);
  overflow-x: auto;
}

._SidebarSection--noBorderTop_125qe_20 > ._SidebarSection-title_125qe_12 {
  border-top: 0px;
}

._SidebarSection-content_125qe_24 {
  padding: 16px;
}

._SidebarSection--noPadding_125qe_28 > ._SidebarSection-content_125qe_24 {
  padding: 0px;
}

._SidebarSection--noPadding_125qe_28 > ._SidebarSection-content_125qe_24:last-child {
  padding-bottom: 4px;
}

._SidebarSection_125qe_1:last-of-type ._SidebarSection-content_125qe_24 {
  border-bottom: none;
  flex-grow: 1;
}

._SidebarSection-breadcrumbLabel_125qe_41 {
  background: none;
  border: 0;
  border-radius: 2px;
  color: var(--puck-color-azure-04);
  cursor: pointer;
  font: inherit;
  flex-shrink: 0;
  padding: 0;
  transition: color 50ms ease-in;
}

._SidebarSection-breadcrumbLabel_125qe_41:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}

@media (hover: hover) and (pointer: fine) {
  ._SidebarSection-breadcrumbLabel_125qe_41:hover {
    color: var(--puck-color-azure-03);
    transition: none;
  }
}

._SidebarSection-breadcrumbLabel_125qe_41:active {
  color: var(--puck-color-azure-02);
  transition: none;
}

._SidebarSection-breadcrumbs_125qe_70 {
  align-items: center;
  display: flex;
  gap: 4px;
}

._SidebarSection-breadcrumb_125qe_41 {
  align-items: center;
  display: flex;
  gap: 4px;
}

._SidebarSection-heading_125qe_82 {
  padding-right: 16px;
}

._SidebarSection-loadingOverlay_125qe_86 {
  background: var(--puck-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 1;
  pointer-events: all;
  box-sizing: border-box;
  opacity: 0.8;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/MenuBar/styles.module.css/#css-module-data */

._MenuBar_8pf8c_1 {
  background-color: var(--puck-color-white);
  border-bottom: 1px solid var(--puck-color-grey-09);
  display: none;
  left: 0;
  margin-top: 1px;
  padding: 8px 16px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
}

._MenuBar--menuOpen_8pf8c_14 {
  display: block;
}

@media (min-width: 638px) {
  ._MenuBar_8pf8c_1 {
    border: none;
    display: block;
    margin-top: 0;
    overflow-y: visible;
    padding: 0;
    position: static;
  }
}

._MenuBar-inner_8pf8c_29 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  justify-content: flex-end;
}

@media (min-width: 638px) {
  ._MenuBar-inner_8pf8c_29 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

._MenuBar-history_8pf8c_45 {
  display: flex;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Puck/styles.module.css/#css-module-data */

._Puck_1g88c_19 {
  --puck-space-px: 16px;
  font-family: var(--puck-font-family);
}

._Puck-portal_1g88c_24 {
  position: relative;
  z-index: 2;
}

._PuckLayout_1g88c_31 {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

._PuckLayout-inner_1g88c_39 {
  --puck-frame-width: auto;
  --puck-side-bar-width: 0px;
  display: grid;
  grid-template-areas: "header header header" "left editor right";
  grid-template-columns: 0 var(--puck-frame-width) 0;
  grid-template-rows: min-content auto;
  height: 100dvh;
  position: relative;
  z-index: 0;
}

._PuckLayout--mounted_1g88c_51 ._PuckLayout-inner_1g88c_39 {
  --puck-side-bar-width: 186px;
}

._PuckLayout--leftSideBarVisible_1g88c_55 ._PuckLayout-inner_1g88c_39 {
  grid-template-columns: var(--puck-side-bar-width) var(--puck-frame-width) 0;
}

._PuckLayout--rightSideBarVisible_1g88c_61 ._PuckLayout-inner_1g88c_39 {
  grid-template-columns: 0 var(--puck-frame-width) var(--puck-side-bar-width);
}

._PuckLayout--leftSideBarVisible_1g88c_55._PuckLayout--rightSideBarVisible_1g88c_61 ._PuckLayout-inner_1g88c_39 {
  grid-template-columns: var(--puck-side-bar-width) var(--puck-frame-width) var(--puck-side-bar-width);
}

@media (min-width: 458px) {
  ._PuckLayout-mounted_1g88c_75 ._PuckLayout-inner_1g88c_39 {
    --puck-frame-width: minmax(266px, auto);
  }
}

@media (min-width: 638px) {
  ._PuckLayout_1g88c_31 ._PuckLayout-inner_1g88c_39 {
    --puck-side-bar-width: minmax(186px, 250px);
  }
}

@media (min-width: 766px) {
  ._PuckLayout_1g88c_31 ._PuckLayout-inner_1g88c_39 {
    --puck-frame-width: auto;
  }
}

@media (min-width: 990px) {
  ._PuckLayout_1g88c_31 ._PuckLayout-inner_1g88c_39 {
    --puck-side-bar-width: 256px;
  }
}

@media (min-width: 1198px) {
  ._PuckLayout_1g88c_31 ._PuckLayout-inner_1g88c_39 {
    --puck-side-bar-width: 274px;
  }
}

@media (min-width: 1398px) {
  ._PuckLayout_1g88c_31 ._PuckLayout-inner_1g88c_39 {
    --puck-side-bar-width: 290px;
  }
}

@media (min-width: 1598px) {
  ._PuckLayout_1g88c_31 ._PuckLayout-inner_1g88c_39 {
    --puck-side-bar-width: 320px;
  }
}

._PuckLayout-header_1g88c_116 {
  background: var(--puck-color-white);
  border-bottom: 1px solid var(--puck-color-grey-09);
  color: var(--puck-color-black);
  grid-area: header;
  position: relative;
  max-width: 100vw;
}

._PuckLayout-headerInner_1g88c_125 {
  align-items: end;
  display: grid;
  gap: var(--puck-space-px);
  grid-template-areas: "left middle right";
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  padding: var(--puck-space-px);
}

._PuckLayout-headerToggle_1g88c_135 {
  color: var(--puck-color-grey-05);
  display: flex;
  margin-left: -4px;
  padding-top: 2px;
}

._PuckLayout--rightSideBarVisible_1g88c_61 ._PuckLayout-rightSideBarToggle_1g88c_142,
._PuckLayout--leftSideBarVisible_1g88c_55 ._PuckLayout-leftSideBarToggle_1g88c_143 {
  color: var(--puck-color-black);
}

._PuckLayout-headerTitle_1g88c_147 {
  align-self: center;
}

._PuckLayout-headerPath_1g88c_151 {
  font-family: var(--puck-font-family-monospaced);
  font-size: var(--puck-font-size-xxs);
  font-weight: normal;
  word-break: break-all;
}

._PuckLayout-headerTools_1g88c_158 {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

._PuckLayout-menuButton_1g88c_164 {
  color: var(--puck-color-grey-05);
  margin-left: -4px;
}

._PuckLayout--menuOpen_1g88c_169 ._PuckLayout-menuButton_1g88c_164 {
  color: var(--puck-color-black);
}

@media (min-width: 638px) {
  ._PuckLayout-menuButton_1g88c_164 {
    display: none;
  }
}

._PuckLayout-leftSideBar_1g88c_143 {
  background: var(--puck-color-grey-12);
  border-right: 1px solid var(--puck-color-grey-09);
  display: flex;
  flex-direction: column;
  grid-area: left;
  overflow-y: auto;
}

._PuckLayout-rightSideBar_1g88c_142 {
  background: var(--puck-color-white);
  border-left: 1px solid var(--puck-color-grey-09);
  display: flex;
  flex-direction: column;
  grid-area: right;
  overflow-y: auto;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Puck/components/Fields/styles.module.css/#css-module-data */

._PuckFields_jp3lw_1 {
  position: relative;
  font-family: var(--puck-font-family);
}

._PuckFields--isLoading_jp3lw_6 {
  min-height: 48px;
}

._PuckFields-loadingOverlay_jp3lw_10 {
  background: var(--puck-color-white);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  top: 0px;
  position: absolute;
  z-index: 1;
  pointer-events: all;
  box-sizing: border-box;
  opacity: 0.8;
}

._PuckFields-loadingOverlayInner_jp3lw_25 {
  display: flex;
  padding: 16px;
  position: sticky;
  top: 0;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/ComponentList/styles.module.css/#css-module-data */

._ComponentList_odh9d_1 {
  max-width: 100%;
}

._ComponentList--isExpanded_odh9d_5 + ._ComponentList_odh9d_1 {
  margin-top: 12px;
}

._ComponentList-content_odh9d_9 {
  display: none;
}

._ComponentList--isExpanded_odh9d_5 > ._ComponentList-content_odh9d_9 {
  display: block;
}

._ComponentList-title_odh9d_17 {
  background-color: transparent;
  border: 0;
  color: var(--puck-color-grey-05);
  cursor: pointer;
  display: flex;
  font: inherit;
  font-size: var(--puck-font-size-xxxs);
  list-style: none;
  margin-bottom: 6px;
  padding: 8px;
  text-transform: uppercase;
  transition: background-color 50ms ease-in, color 50ms ease-in;
  gap: 4px;
  border-radius: 4px;
  width: 100%;
}

._ComponentList-title_odh9d_17:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}

@media (hover: hover) and (pointer: fine) {
  ._ComponentList-title_odh9d_17:hover {
    background-color: var(--puck-color-azure-11);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}

._ComponentList-title_odh9d_17:active {
  background-color: var(--puck-color-azure-10);
  transition: none;
}

._ComponentList-titleIcon_odh9d_53 {
  margin-left: auto;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Puck/components/Preview/styles.module.css/#css-module-data */

._PuckPreview_rxwlr_1 {
  height: 100%;
}

._PuckPreview-frame_rxwlr_5 {
  border: none;
  min-height: 100%;
  width: 100%;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/LayerTree/styles.module.css/#css-module-data */

._LayerTree_1pgw8_1 {
  color: var(--puck-color-grey-03);
  font-family: var(--puck-font-family);
  font-size: var(--puck-font-size-xxs);
  margin: 0;
  position: relative;
  list-style: none;
  padding: 0;
}

._LayerTree-zoneTitle_1pgw8_11 {
  color: var(--puck-color-grey-05);
  font-size: var(--puck-font-size-xxxs);
  text-transform: uppercase;
}

._LayerTree-helper_1pgw8_17 {
  text-align: center;
  color: var(--puck-color-grey-07);
  margin: 8px 4px;
}

._Layer_1pgw8_1 {
  position: relative;
  border: 1px solid transparent;
  border-radius: 4px;
}

._Layer-inner_1pgw8_29 {
  border: 1px solid transparent;
  border-radius: 4px;
  transition: color 50ms ease-in;
}

._Layer--containsZone_1pgw8_35 > ._Layer-inner_1pgw8_29 {
  padding-left: 0;
}

._Layer-clickable_1pgw8_39 {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  display: flex;
  font: inherit;
  padding-left: 12px;
  padding-right: 4px;
  width: 100%;
}

._Layer-clickable_1pgw8_39:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
  position: relative;
  z-index: 1;
}

@media (hover: hover) and (pointer: fine) {
  ._Layer_1pgw8_1:not(._Layer--isSelected_1pgw8_61) > ._Layer-inner_1pgw8_29:hover {
    border-color: var(--puck-color-azure-10);
    background: var(--puck-color-azure-11);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}

._Layer--isSelected_1pgw8_61 {
  border-color: var(--puck-color-azure-08);
}

._Layer--isSelected_1pgw8_61 > ._Layer-inner_1pgw8_29 {
  background: var(--puck-color-azure-10);
}

._Layer--isSelected_1pgw8_61 > ._Layer-inner_1pgw8_29 > ._Layer-clickable_1pgw8_39 > ._Layer-chevron_1pgw8_77,
._Layer--childIsSelected_1pgw8_78 > ._Layer-inner_1pgw8_29 > ._Layer-clickable_1pgw8_39 > ._Layer-chevron_1pgw8_77 {
  transform: scaleY(-1);
}

._Layer-zones_1pgw8_82 {
  display: none;
  margin-left: 12px;
}

._Layer--isSelected_1pgw8_61 > ._Layer-zones_1pgw8_82,
._Layer--childIsSelected_1pgw8_78 > ._Layer-zones_1pgw8_82 {
  display: block;
}

._Layer-zones_1pgw8_82 > ._LayerTree_1pgw8_1 {
  margin-left: 12px;
}

._Layer-title_1pgw8_96,
._LayerTree-zoneTitle_1pgw8_11 {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 8px 4px;
  overflow-x: hidden;
}

._Layer-name_1pgw8_105 {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._Layer-icon_1pgw8_111 {
  color: var(--puck-color-rose-07);
  margin-top: 4px;
}

._Layer-zoneIcon_1pgw8_116 {
  color: var(--puck-color-grey-08);
  margin-top: 4px;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/ViewportControls/styles.module.css/#css-module-data */

._ViewportControls_g1wgg_1 {
  display: flex;
  background: var(--puck-color-grey-11);
  box-sizing: border-box;
  border-left: 2px solid var(--puck-color-grey-11);
  justify-content: center;
  gap: 8px;
  min-width: 358px;
  padding-bottom: 16px;
  padding-left: var(--puck-space-px);
  padding-right: var(--puck-space-px);
  z-index: 1;
}

._ViewportControls-divider_g1wgg_15 {
  border-right: 1px solid var(--puck-color-grey-09);
  margin-left: 8px;
  margin-right: 8px;
}

._ViewportControls-zoomSelect_g1wgg_21 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23c3c3c3'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 10px;
  background-position: calc(100% - 12px) calc(50% + 3px);
  background-repeat: no-repeat;
  border: 0;
  font-size: var(--puck-font-size-xxxs);
  padding: 0;
  width: 96px;
}

._ViewportButton--isActive_g1wgg_34 ._ViewportButton-inner_g1wgg_34 {
  color: var(--puck-color-azure-04);
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Puck/components/Canvas/styles.module.css/#css-module-data */

._PuckCanvas_avf1c_1 {
  background: var(--puck-color-grey-11);
  display: flex;
  grid-area: editor;
  flex-direction: column;
  padding: var(--puck-space-px);
  overflow: auto;
}

@media (min-width: 1198px) {
  ._PuckCanvas_avf1c_1 {
    padding: calc(var(--puck-space-px) * 1.5);
    padding-top: var(--puck-space-px);
  }
  ._PuckCanvas_avf1c_1:not(._PuckCanvas_avf1c_1:has(._PuckCanvas-controls_avf1c_16)) {
    padding-top: calc(var(--puck-space-px) * 1.5);
  }
}

._PuckCanvas-inner_avf1c_21 {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 358px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

._PuckCanvas-root_avf1c_32 {
  background: white;
  border: 1px solid var(--puck-color-grey-09);
  box-sizing: border-box;
  min-width: 321px;
  position: absolute;
  pointer-events: none;
  transform-origin: top;
  top: 0;
  bottom: 0;
  opacity: 0;
}

@media (min-width: 1198px) {
  ._PuckCanvas-root_avf1c_32 {
    min-width: unset;
  }
}

@media (prefers-reduced-motion: reduce) {
  ._PuckCanvas-root_avf1c_32 {
    transition: none !important;
  }
}

._PuckCanvas--ready_avf1c_57 ._PuckCanvas-root_avf1c_32 {
  pointer-events: unset;
  opacity: 1;
}

._PuckCanvas-loader_avf1c_62 {
  align-items: center;
  color: var(--puck-color-grey-06);
  display: flex;
  height: 100%;
  justify-content: center;
  transition: opacity 250ms ease-out;
  opacity: 0;
}

._PuckCanvas--showLoader_avf1c_72 ._PuckCanvas-loader_avf1c_62 {
  opacity: 1;
}

._PuckCanvas--showLoader_avf1c_72._PuckCanvas--ready_avf1c_57 ._PuckCanvas-loader_avf1c_62 {
  opacity: 0;
  height: 0;
  transition: none;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}

@keyframes modal-video{from{opacity:0}to{opacity:1}}@keyframes modal-video-inner{from{transform:translate(0, 100px)}to{transform:translate(0, 0)}}.modal-video{position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.5);z-index:1000000;cursor:pointer;opacity:1;animation-timing-function:ease-out;animation-duration:.3s;animation-name:modal-video;transition:opacity .3s ease-out}.modal-video-effect-exit{opacity:0}.modal-video-effect-exit .modal-video-movie-wrap{transform:translate(0, 100px)}.modal-video-body{max-width:960px;width:100%;height:100%;margin:0 auto;padding:0 10px;display:flex;justify-content:center;box-sizing:border-box}.modal-video-inner{display:flex;justify-content:center;align-items:center;width:100%;height:100%}@media(orientation: landscape){.modal-video-inner{padding:10px 60px;box-sizing:border-box}}.modal-video-movie-wrap{width:100%;height:0;position:relative;padding-bottom:56.25%;background-color:#333;animation-timing-function:ease-out;animation-duration:.3s;animation-name:modal-video-inner;transform:translate(0, 0);transition:transform .3s ease-out}.modal-video-movie-wrap iframe{position:absolute;top:0;left:0;width:100%;height:100%}.modal-video-close-btn{position:absolute;z-index:2;top:-45px;right:0px;display:inline-block;width:35px;height:35px;overflow:hidden;border:none;background:rgba(0,0,0,0)}@media(orientation: landscape){.modal-video-close-btn{top:0;right:-45px}}.modal-video-close-btn:before{transform:rotate(45deg)}.modal-video-close-btn:after{transform:rotate(-45deg)}.modal-video-close-btn:before,.modal-video-close-btn:after{content:"";position:absolute;height:2px;width:100%;top:50%;left:0;background:#fff;border-radius:5px;margin-top:-6px}

